import React, { useState, useEffect, useRef } from "react";
import {
  ArrowRightOnRectangleIcon,
  Bars3BottomLeftIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SideBar from "../sideBar/SideBar";
import { signOut } from "../../services/auth.service";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { IMsalContext, useMsal } from "@azure/msal-react";
import Avatar, { ConfigProvider } from 'react-avatar';
const Header = () => {
  const navigate = useNavigate();
  const [isToggled, setToggled] = useState(false);
  const { User } = useSelector((state: RootState) => state?.auth);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Toggle sidebar visibility
  const handleButtonClick = () => {
    setToggled(!isToggled);
  };

  // Toggle profile dropdown visibility
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const {instance} = useMsal()

  async function logOut() {
    instance.logout();
    signOut();
    navigate("/sign-in");
  }

  console.log('instance',instance)
  const user:any = JSON.parse(localStorage.getItem("user")!)

  return (
    <>
      <div className="flex justify-between items-center py-[18px] px-[15px] xl:px-[30px] relative min-w-full">
        <div className="xl:hidden">
          <button className="btn-mblPrfl" onClick={handleButtonClick}>
            <Bars3BottomLeftIcon className="text-[#333333] w-6 h-6" />
          </button>
          <div className={`sign-up-component ${isToggled ? "toggled" : ""}`}>
            <SideBar handleButtonClick={handleButtonClick} />
          </div>
        </div>

        <img src="/images/weblogo.svg" alt="web logo" />

        <button
          onClick={handleToggle}
          className="flex items-center gap-2 py-1 ps-4 pr-[6px]"
        >
         {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="w-9 h-9 text-[#33475F]"><path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clip-rule="evenodd"></path></svg> */}
         <Avatar  size='40px' round name={user.name} src={user?.imageURL} />

          <span className="text-base font-semibold text-[#333] md:block hidden capitalize">
            {User?.name}
          </span>
        </button>
        {isOpen && (
          <div
            ref={dropdownRef}
            className="absolute top-16 mt-3 bg-white rounded-lg right-[30px] shadow-3xl p-1 lg:p-3 w-[230px] lg:w-[310px]"
          >
            {/* Dropdown content goes here */}
            <ul>
              <li
                className={`${
                  location.pathname !== "/profile" ? "text-black" : ""
                } p-4 hover:bg-[#DDECF4] active:bg-[#DDECF4] text-[#33475F] text-base font-semibold rounded-lg mb-1`}
              >
                <button
                  className="gap-3 flex items-start w-full"
                  onClick={() => {
                    navigate("/profile");
                    setIsOpen(false); // Close dropdown after navigation
                  }}
                >
                  <UserIcon className="w-6 h-6" />
                  <span>Profile</span>
                </button>{" "}
              </li>
              <li className="p-4 hover:bg-[#506379] bg-[#33475F] text-white text-base font-semibold rounded-lg">
                <button
                  className="gap-3 flex items-start w-full"
                  onClick={
                     logOut
                  }
                >
                  <ArrowRightOnRectangleIcon className="w-6 h-6" />
                  <span>Sign out</span>
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
