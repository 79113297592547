import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  ArrowUpTrayIcon,
  ChatBubbleBottomCenterTextIcon,
  ChevronDownIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import ApproveRejectModal from "../Modals/ApproveRejectModal";
import TablePagination from "../pagination/TablePagination";
import ReactDatePicker from "react-datepicker";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";
import {
  exportAssetsCSV,
  fetchAllAssets,
  updateAssetReqStatus,
} from "../../services/assetRequisition.service";
import {
  formatDate,
  formatDateSlash,
  formatDateSpace,
} from "../../utils/dateFromatter";
import { IAssetsReq, Asset } from "../../types/interfaces/IAssetsReq.interface";
import ReasonPopOver from "../../widgets/ReasonPopOver";
import moment from "moment";
import AssetsPopOver from "../../widgets/AssetsPopOver";
import { scrollToTop } from "../../utils/tableScroller";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

interface Props {
  getAssetsInfo: () => void;
  openPopoverId: string | null;
  setOpenPopoverId: (id: string | null) => void;
  openPopoverAssetId: string | null;
  setOpenPopoverAssetId: (id: string | null) => void;
  getAssests: Function;
}

const AssetTable: React.FC<Props> = ({
  getAssetsInfo,
  openPopoverId,
  setOpenPopoverId,
  openPopoverAssetId,
  setOpenPopoverAssetId,
  getAssests,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [assetReqRequests, setAssetReqRequests] = useState<IAssetsReq>({
    records: [],
    pendingCount: 0,
  });
  const [comments, setComments] = useState("");
  const [reqAgain, setReqAgain] = useState(false);
  const [actionToPerform, setActionToPerform] = useState("");
  const [selectedRecord, setSelectedRecord] = useState<Asset>();
  const [isListOpen, setIsListOpen] = useState(false);
  const [dateRange, setDateRange] = useState<Date[]>([]);
  const [leaveFrom, leaveTo] = dateRange?.length > 0 ? dateRange : [];
  // const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);
  const [scrollOnDate, setScrollOnDate] = useState(false);
  let timeoutId: NodeJS.Timeout | null = null;
  let scrollToTopRef = useRef<HTMLTableSectionElement>(null);

  const getAllAssets = async (
    startDate?: string,
    endDate?: string,
    searchQuery?: string
  ) => {
    const res = await getAssests(startDate, endDate, searchQuery);
    if (res) {
      setAssetReqRequests(res as IAssetsReq);
    }
  };

  const handleAssetReqAction = async (assetReqData: Asset) => {
    const reqBody = {
      comment: comments,
    };
    const res = await updateAssetReqStatus(
      actionToPerform,
      assetReqData?.ID,
      reqBody
    );
    if (res?.res) {
      setReqAgain(!reqAgain);
      if (actionToPerform === "rejected") {
        toast.success("Assets Request Rejected Successfully");
      } else {
        toast.success("Assets Request Approved Successfully");
      }
      getAssetsInfo();
      closeModal();
    }
  };

  const handleExportCSV = async () => {
    const res: any = await fetchAllAssets();

    if (res?.res) {
      const data = res.res.records || [];

      const exportToXLSX = (
        filename: string,
        data: any[],
        sheetName: string
      ) => {
        const workBook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
        worksheet["!cols"] = [
          { wch: 35 },
          { wch: 25 },
          { wch: 30 },
          { wch: 30 },
          { wch: 20 },
          { wch: 15 },
          { wch: 30 },
          { wch: 15 },
          { wch: 15 },
        ];
        XLSX.utils.book_append_sheet(workBook, worksheet, sheetName);
        XLSX.writeFile(workBook, `${filename}.xlsx`);
      };

      const processRows = (data: any[]) => {
        return data.map((row, index) => ({
          // "Sr. No": index + 1,

          Name: row.UserName,
          Title: row.Title,
          AssetName: row.AssetName.join(", "), // Assuming AssetName is an array
          Reason: row.Reason,
          Status: row.Status,
          Comment: row.Comment || "-",
          DateApplied: formatDate(row.DateApplied),

          // IssueDate: formatDate(row.IssueDate),
          // ReturnDate: formatDate(row.ReturnDate),
        }));
      };
      const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-GB"); // Adjust locale based on your preference
      };
      const saveAsXlsx = () => {
        const formattedData = processRows(data);
        exportToXLSX("AssetData", formattedData, "Asset Data");
      };
      saveAsXlsx();
    } else {
      console.error("Error fetching asset requests:", res?.error);
      // Handle error here
    }
  };

  const handleComments = (comments: string) => {
    setComments(comments);
  };
  useEffect(() => {
    if (!dateRange[1]) return;
    setReqAgain(!reqAgain);
    setScrollOnDate(!scrollOnDate);
  }, [dateRange]);

  useEffect(() => {
    if (searchQuery) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        if (dateRange?.length > 0) {
          getAllAssets(
            formatDateSlash(leaveFrom),
            formatDateSlash(leaveTo),
            searchQuery
          );
        } else getAllAssets(undefined, undefined, searchQuery);
      }, 1000);
    } else if (dateRange?.length > 0) {
      getAllAssets(formatDateSlash(leaveFrom), formatDateSlash(leaveTo));
    } else {
      getAllAssets();
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [reqAgain]);

  // for file export from healthcare
  // const handleDownloadFilePattern = async () => {
  //   const res: any = await getFilePattern(viewOption as string);
  //   if (res?.url) {
  //     window.open(res?.url, "_blank");
  //     console.log("check file: ", res);
  //   } else {
  //     store.dispatch(setSnackBar("error"));
  //     store.dispatch(
  //       setToastMessage("Sorry, couldn't download template file.")
  //     );
  //   }
  // };
  const openModal = (event: any, record: Asset) => {
    setIsModalOpen(true);
    setActionToPerform(event?.target?.name);
    setSelectedRecord(record);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setComments("");
  };

  const togglePopover = (record: Asset) => {
    if (openPopoverId === record.ID) {
      setOpenPopoverId(null);
    } else {
      setOpenPopoverId(record.ID);
    }
    setSelectedRecord(record);
    isListOpen && !isPopoverOpen && setIsListOpen(false);
  };

  const toggleAssetsList = (record: Asset) => {
    if (openPopoverAssetId === record.ID) {
      setOpenPopoverAssetId(null);
    } else {
      setOpenPopoverAssetId(record.ID);
    }
    setSelectedRecord(record);
    // setIsListOpen(!isListOpen);
    !isListOpen && isPopoverOpen && setIsPopoverOpen(false);
  };
  const handleClear = () => {
    setDateRange([]);
    setReqAgain(!reqAgain);
  };

  useEffect(() => {
    scrollToTop(scrollToTopRef);
  }, [searchQuery, scrollOnDate]);

  // Memoize filtered assets based on search query
  const filteredAssets = useMemo(() => {
    if (!searchQuery) return assetReqRequests.records;
    return assetReqRequests.records.filter((asset) =>
      asset.UserName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, assetReqRequests.records]);

  const handleKeyDown = (e:any ) => {
    e.preventDefault(); // Prevent all key presses
  };

  return (
    <>
      <div className="py-5">
        <div className="relative flex md:flex-row flex-col md:items-center mb-3 md:mb-6 gap-3">
          <input
            className="cursor-text w-full md:max-w-[310px] py-[10px] ps-10 pe-4  border border-[#D6D6D6] bg-[#ffffff] rounded-lg focus:outline-none placeholder:text-sm"
            type="search"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e?.target?.value)}
          />
          <span className="absolute top-3 left-3">
            <MagnifyingGlassIcon className="w-5 h-5 text-[#858585]" />
          </span>
          <div className="relative flex items-center gap-1 sm:gap-2 z-10 w-full md:w-auto">
            <ReactDatePicker
              className="cursor-pointer rounded-lg md:min-w-[230px] w-full bg-[#ffffff] border border-[#D6D6D6] text-sm py-3 px-5 placeholder:text-[#333] placeholder:font-medium"
              onChange={(date) => {
                setDateRange(date as Date[]);
              }}
              startDate={leaveFrom}
              endDate={leaveTo}
              placeholderText="Select Range"
              selectsRange={true}
              dateFormat="dd/MM/yyyy"
              preventOpenOnFocus
              onKeyDown={(e) => handleKeyDown(e)}
            />
            {leaveTo ? (
              <button
                className="absolute top-3 right-4 items-center"
                onClick={handleClear}
              >
                <XMarkIcon className="text-black w-5 h-5  right-4" />
              </button>
            ) : (
              <span className="absolute top-3 right-4 pointer-events-none">
                <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
              </span>
            )}
          </div>
          <button
            className="btnExport text-xs min-w-[85px] font-semibold text-[#378EB5] flex items-center w-[107px] ml-auto gap-1 bg-[rgb(55,142,181)] text-white py-[9px] px-[10px]"
            onClick={() => handleExportCSV()}
          >
            <ArrowUpTrayIcon className="w-4 h-4" />
            Export CSV
          </button>
        </div>

        {assetReqRequests?.records?.length > 0 ? (
          <>
            <div
              className="Employees md:px-0 overflow-scroll overflow-x-hidden h-[calc(100vh-335px)]"
              ref={scrollToTopRef}
            >
              <table className="leaveMange w-full">
                <thead className="capitalize sticky top-0">
                  <tr>
                    <th></th>
                    <th>Employee Name</th>
                    <th>Assets</th>
                    <th>Title</th>
                    <th>Reason</th>
                    <th>Date Applied</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAssets.map((req, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="capitalize" data-cell="Employee Name">
                        {req?.UserName}
                      </td>
                      <td data-cell="Assests" className="relative">
                        <button
                          className={`text-sm text-[#378EB5] font-semibold ${
                            openPopoverAssetId &&
                            openPopoverAssetId?.length > 0 &&
                            (openPopoverAssetId === req.ID
                              ? ""
                              : "cursor-default")
                          }`}
                          onClick={() => toggleAssetsList(req)}
                        >
                          <svg
                            className="w-6 h-6 text-#378EB5  dark:text-#378EB5 "
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#378EB5 "
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-width="2"
                              d="M9 8h10M9 12h10M9 16h10M4.99 8H5m-.02 4h.01m0 4H5"
                            />
                          </svg>
                        </button>
                        {openPopoverAssetId === req.ID && (
                          <AssetsPopOver
                            record={selectedRecord?.AssetName as string[]}
                            onClose={() => setOpenPopoverAssetId(null)}
                          />
                        )}
                      </td>
                      <td data-cell="Title" className="capitalize">
                        {req?.Title}
                      </td>

                      <td data-cell="Reason">
                        <div className="relative">
                          <button
                            onClick={() => togglePopover(req)}
                            className={`text-sm text-[#378EB5] font-semibold ${
                              openPopoverId &&
                              openPopoverId?.length > 0 &&
                              (openPopoverId === req.ID ? "" : "cursor-default")
                            }`}
                          >
                            <ChatBubbleBottomCenterTextIcon className="text-#378EB5 w-5 h-5" />
                          </button>
                          {/* {isPopoverOpen && selectedRecord?.ID === req.ID && (
                        <ReasonPopOver
                              reason={selectedRecord?.Reason as string}
                            />
                          )} */}
                          {openPopoverId === req.ID && (
                            <ReasonPopOver
                              reason={req.Reason as string}
                              onClose={() => setOpenPopoverId(null)}
                            />
                          )}
                        </div>
                      </td>
                      <td data-cell="Date Applied">
                        {formatDateSpace(
                          moment(req?.DateApplied).format("DD/MM/YYYY")
                        )}
                      </td>
                      <td>
                        <div className="flex items-center justify-end gap-1">
                          {req?.Status === "pending" ? (
                            <>
                              <button
                                className="btnApprove"
                                name="approved"
                                onClick={(event) => openModal(event, req)}
                              >
                                Approve
                              </button>
                              <button
                                className="btnReject"
                                name="rejected"
                                onClick={(event) => openModal(event, req)}
                              >
                                Reject
                              </button>
                            </>
                          ) : req?.Status === "approved" ? (
                            <span className="bg-[#23CDAF] text-white font-semibold text-[10px] rounded-[4px] px-2 py-0.5 uppercase">
                              Approved
                            </span>
                          ) : (
                            <span className="bg-[#333333] text-white font-semibold text-[10px] rounded-[4px] px-2 py-0.5 uppercase">
                              Rejected
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* <TablePagination /> */}

            <ApproveRejectModal
              isOpen={isModalOpen}
              onClose={closeModal}
              comments={comments}
              handleComments={handleComments}
              recordData={selectedRecord}
              actionToPerform={actionToPerform}
              sendRequest={handleAssetReqAction}
            />
          </>
        ) : (
          <div className="text-center flex flex-col justify-center mt-16">
            <span className="text-center flex justify-center mb-3">
              <img src="/images/no-data.png" alt="no have data" />{" "}
            </span>
            <p className="text-sm font-normal text-[#5C5C5C]">
              No Asset Request Available.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default AssetTable;
