import { Configuration, PopupRequest } from "@azure/msal-browser";
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_AD_TENANT_ID}`,
    redirectUri:`${window.location.origin}`,
    postLogoutRedirectUri: `${window.location.origin}/sign-in`,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

// export const protectedResources = {
//   apiService: {
//     endpoint: process.env.REACT_APP_API_URL,
//     scopes: ["api://readynowcaresolutions.com/customer-portal/Data.Read"],
//   },
// };

// export const loginRequest = {
//   scopes: ["api://readynowcaresolutions.com/customer-portal/Data.Read"],
// };

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
